import React from "react";
import { Theme, themeOptions } from "@commonsku/styles";
import { ToastContainer } from "react-toastify";
import '../../scss/css/react-toastify.css';
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "./Header";
import MainSection from "./MainSection";
import Overlay from "../components/Overlay";
import { useSelector } from "react-redux";
import { getPopups } from "../selectors";

import { GlobalStyle } from "./DashboardApp";
import FinancePageRouter from "../components/finance/FinancePageRouter";

const FinanceApp = () => {
  const popups = useSelector(getPopups);

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <Header />
        <MainSection>
          <FinancePageRouter />
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
           />
        </MainSection>
        <Overlay popups={popups} />
      </ErrorBoundary>
    </Theme>
  );
};

export default FinanceApp;
