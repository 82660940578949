import React, { CSSProperties, useMemo } from "react";
import {
  ArrowIcon,
  Button,
  Col,
  Csku,
  Row,
  useWindowSize,
  TabBar,
  Tab,
} from "@commonsku/styles";
import DashboardPageTitle from "../dashboard/DashboardPageTitle";
import ExportReportButton from "./ExportReportButton";
import CreateCreditMemoButton from "./CreateCreditMemoButton";
import ErrorBoundary from "../ErrorBoundary";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";
import NoMatch from "../NoMatch";
import { ReportProvider } from "../report/report_context";
import { getReportConfig, ReportConfig } from "../report/config";
import ReportFiltersContainer from "../report/filters/ReportFiltersContainer";
import ReportSummaryContainer from "../report/summary/ReportSummaryContainer";
import ReportTable from "../report/table/ReportTable";
import Pagination from "../report/table/Pagination";
import ActionBar from "../report/table/ActionBar";
import Loading from "../report/Loading";
import { useSelector } from "react-redux";
import { getPopups } from "../../selectors";
import createPopup from "../../popup-factory";

import { EReportTypes } from "../report/routes/report_types";
import { REPORT_TYPES, getReportTabs, getPageTitle } from "./report_types";
import { useHasCapabilities } from "../../hooks";

const reportContainerStyle: CSSProperties = {
  width: "100%",
  minHeight: "100vh",
  height: "100%",
  background: "#E6EFF2",
  marginTop: 0,
  paddingTop: 24,
  paddingRight: 24,
};

const actionBarStyle: CSSProperties = {
  width: "auto",
  left: "176px",
  right: "0px",
};

const FinanceContainer = () => {
  const popups = useSelector(getPopups);
  const params = useParams();
  const { param } = params;
  const windowHeight = useWindowSize()[1];
  const navigate = useNavigate();

  const report_type = `finance-${param}`.toUpperCase();

  const reportConfig: ReportConfig = useMemo(
    () => getReportConfig(report_type),
    [report_type],
  );

  const tabs: (keyof typeof REPORT_TYPES)[] = useMemo(
    () => getReportTabs(report_type),
    [report_type],
  );

  const capabilities = REPORT_TYPES?.[reportConfig?.type]?.capabilities ?? [];
  const hasAccess = useHasCapabilities(capabilities);

  if (!reportConfig || !hasAccess) {
    return null;
  }

  return (
    <ReportProvider
      key={report_type}
      reportConfig={reportConfig}
      containerHeight={windowHeight}
    >
      <Csku as={"div"} className="main-content csku-styles">
        <Row style={{ justifyContent: "space-between" }}>
          <Col
            style={{ display: "flex", alignItems: "center", paddingLeft: 8 }}
          >
            <DashboardPageTitle
              title={getPageTitle(reportConfig.type)}
            />
          </Col>
          <Col padded style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
            <ExportReportButton />
            <CreateCreditMemoButton />
          </Col>
        </Row>

        <TabBar>
          {tabs.map(
            (tab, index) => (
              <Tab
                key={tab}
                className={reportConfig.type === tab ? 'selected' : ''}
                variant="secondary"
                selected={reportConfig.type === tab}
                onClick={() => {
                  const url = REPORT_TYPES[tab]?.path;
                  if (url) {
                    navigate(url);
                  }
                }}
              >
                {REPORT_TYPES[tab]?.title}
              </Tab>
            )
          )}
        </TabBar>
        <ReportFiltersContainer />
        <ReportSummaryContainer />
        <ReportTable />
        <Pagination />
        <ActionBar style={actionBarStyle} />
        <Loading />
        {popups ? popups.map((p, idx) => createPopup(p, idx)) : null}
      </Csku>
    </ReportProvider>
  );
};

const FinancePageRouter = ({ basename }: { basename?: string }) => {
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route
          path="/finance/:param"
          element={<FinanceContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<NoMatch location={location} />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default FinancePageRouter;
