import React, { useCallback } from "react";
import { Button } from "@commonsku/styles";
import { useReportContext } from "../report/report_context";
import { useHasCapabilities } from "../../hooks";

const DEFAULT_EXPORT_URL = "export_report.php";

const ExportReportButton = () => {
  const {
    reportConfig: { type, exportUrl = DEFAULT_EXPORT_URL },
    getRawQuery,
  } = useReportContext();
  const hasExportReport = useHasCapabilities("EXPORT-REPORT");

  const exportReport = useCallback(
    () => {
      const query = getRawQuery();
      query["report_type"] = type.toLowerCase().replace(/^finance-/, "");
      window.open(
        `${window.location.origin}/${exportUrl}?${new URLSearchParams(
          query,
        ).toString()}`,
      );
    },
    [exportUrl, getRawQuery, type],
  );

  if (!hasExportReport) {
    return null;
  }

  return (
    <Button secondary onClick={exportReport}>
      Export CSV
    </Button>
  );
};

export default ExportReportButton;
